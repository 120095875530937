'use client';

import { AppShell } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import 'dayjs/locale/it';
import { useSession } from 'next-auth/react';

import Header from './Header';
import Navbar from './Navbar';

const PageLayout = ({ children, sparkUrl }) => {
    const { status } = useSession();
    //Boolean(session?.error) && signOut();
    const [mobileOpened, { toggle: toggleMobile }] = useDisclosure(false);
    const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(false);
    return (
        <DatesProvider settings={{ locale: 'it', consistentWeeks: true, timezone: 'Europe/Rome' }}>
            <ModalsProvider>
                <AppShell
                    header={{ height: 70 }}
                    navbar={{
                        width: 250,
                        breakpoint: 'sm',
                        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
                    }}
                    padding="md"
                >
                    <Header
                        mobileOpened={mobileOpened}
                        desktopOpened={desktopOpened}
                        toggleDesktop={toggleDesktop}
                        toggleMobile={toggleMobile}
                    />
                    {status === 'authenticated' && <Navbar sparkUrl={sparkUrl} toggleMobile={toggleMobile} />}
                    <AppShell.Main>{children}</AppShell.Main>
                </AppShell>
            </ModalsProvider>
        </DatesProvider>
    );
};

export default PageLayout;
