import { AppShell, Burger, Group, Title } from '@mantine/core';
import { useSession } from 'next-auth/react';

import { HeaderMenu } from './ui';

const Header = ({ mobileOpened, toggleMobile, desktopOpened, toggleDesktop }) => {
    const { status } = useSession();
    return (
        <AppShell.Header bg="blue">
            <Group h="100%" px="md" justify="space-between">
                <Group>
                    {status === 'authenticated' && (
                        <>
                            <Burger
                                color="white"
                                opened={mobileOpened}
                                onClick={toggleMobile}
                                hiddenFrom="sm"
                                size="sm"
                            />
                            <Burger
                                color="white"
                                opened={desktopOpened}
                                onClick={toggleDesktop}
                                visibleFrom="sm"
                                size="sm"
                            />
                        </>
                    )}
                    <Title c="white" hiddenFrom="sm" order={4}>
                        Enami DataLogger
                    </Title>
                    <Title c="white" visibleFrom="sm" order={3}>
                        Enami DataLogger
                    </Title>
                </Group>
                {status === 'authenticated' && <HeaderMenu />}
            </Group>
        </AppShell.Header>
    );
};

export default Header;
