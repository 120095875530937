'use client';

import { StandardLoader } from '@/components/ui/loaders';
import { useMutation, useQuery } from '@apollo/client';
import { useFetch } from '@mantine/hooks';
import _ from 'lodash';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

import { useApiTypes, usePlants, useSun } from '../store';
import { insertUserMutation } from './mutations';
import {
    getApiTypesQuery,
    getPlantsQuery,
    getPowerMeasuresQuery,
    getScintillaPlantsQuery,
    getUserQuery,
} from './queries';

const date = new Date(new Date().setHours(0, 0, 0, 0));

const Initialization = ({ children }) => {
    const { data: session, status } = useSession();

    const { data: plants } = useQuery(getPlantsQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            date,
        },
        skip: status === 'unauthenticated',
    });

    const { data: measures } = useQuery(getPowerMeasuresQuery, {
        variables: {
            date,
        },
        skip: status === 'unauthenticated',
    });

    const { data: scintillaPlants } = useQuery(getScintillaPlantsQuery, {
        skip: status === 'unauthenticated',
    });

    const { data: apiTypes } = useQuery(getApiTypesQuery, { skip: status === 'unauthenticated' });

    const { data: users } = useQuery(getUserQuery, { skip: status === 'unauthenticated', fetchPolicy: 'no-cache' });
    const [insertUser] = useMutation(insertUserMutation, {
        refetchQueries: ['getUser'],
        variables: { email: session?.data.email },
    });

    const { setPlants, setPowerMeasuresData, setScintillaPlants } = usePlants();
    const { setSunset, setSunrise } = useSun();
    const { setApiTypes } = useApiTypes();

    const [everythingIsLoaded, setEverythingIsLoaded] = useState(status === 'unauthenticated');

    const { data: sunData, error: errorSunData } = useFetch('https://api.sunrisesunset.io/json?lat=43.6&lng=13.5');

    useEffect(() => {
        if (
            [users, measures, plants, apiTypes, scintillaPlants].every((query) => Boolean(query)) &&
            (Boolean(sunData) || Boolean(errorSunData))
        ) {
            if (users.user.length === 0) {
                insertUser();
            } else {
                const data = Object.assign(
                    {},
                    ...Object.entries({ ...[...Array(22).keys()].slice(6) }).map(([_, hour]) => ({
                        [hour]: [],
                    }))
                );
                const groupedMeasures = measures.powerMeasure.reduce((res, cur) => {
                    let measureDate = new Date(cur.date);
                    const offset = measureDate.getTimezoneOffset() * 60000;
                    measureDate = new Date(measureDate.getTime() - offset);
                    if (!res[cur.plantId]) {
                        res = { ...res, [cur.plantId]: { ..._.cloneDeep(data) } };
                    }
                    if (Boolean(res[cur.plantId][measureDate.getHours()])) {
                        res[cur.plantId][measureDate.getHours()].push({ power: cur.power });
                    }
                    return res;
                }, {});
                const powerMeasuresData = Object.keys(groupedMeasures).map((key) => {
                    return {
                        plantId: parseInt(key),
                        data: Object.keys(groupedMeasures[key]).map((hour) => {
                            let data = { date: hour, Power: 0 };
                            if (groupedMeasures[key][hour].length > 0) {
                                data = {
                                    date: hour,
                                    Power:
                                        groupedMeasures[key][hour].map((d) => d.power).reduce((a, b) => a + b, 0) /
                                        groupedMeasures[key][hour].length,
                                };
                            }
                            return data;
                        }),
                    };
                });
                setPlants(plants.plant);
                setScintillaPlants(scintillaPlants.plant);
                setPowerMeasuresData(powerMeasuresData);
                setApiTypes(apiTypes.apiType);
                setEverythingIsLoaded(true);

                const today = new Date().toISOString().split('T')[0];
                if (!Boolean(errorSunData)) {
                    setSunrise(new Date(`${today} ${sunData?.results?.sunrise || '6:00:00 AM'}`));
                    setSunset(new Date(`${today} ${sunData?.results?.sunset || '7:00:00 PM'}`));
                }
            }
        }
    }, [users, measures, plants, apiTypes, scintillaPlants]);

    return everythingIsLoaded ? children : <StandardLoader />;
};

export default Initialization;
