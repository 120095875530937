'use client';

import { ApolloLink, HttpLink, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import {
    ApolloNextAppProvider,
    NextSSRApolloClient,
    NextSSRInMemoryCache,
    SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import { getSession } from 'next-auth/react';

const makeClient = (secure, gqlEndpoint) => {
    const queryLink = new HttpLink({
        uri: secure ? `https://${gqlEndpoint}` : `http://${gqlEndpoint}`,
    });

    const subscriptionLink = new WebSocketLink({
        uri: secure ? `wss://${gqlEndpoint}` : `ws://${gqlEndpoint}`,
        options: {
            reconnect: true,
            connectionParams: async () => {
                let session;
                if (typeof window !== 'undefined') {
                    session = await getSession();
                }
                return {
                    headers: {
                        'auth-mode': 'firebase',
                        'auth-token': session ? `Bearer ${session.data.stsTokenManager.accessToken}` : '',
                    },
                };
            },
        },
    });

    const authMiddleware = setContext(async (_, { headers }) => {
        let session;
        if (typeof window !== 'undefined') {
            session = await getSession();
        }
        return {
            headers: {
                ...headers,
                'auth-mode': 'firebase',
                'auth-token': session ? `Bearer ${session.data.stsTokenManager.accessToken}` : '',
            },
        };
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        subscriptionLink,
        authMiddleware.concat(queryLink)
    );

    return new NextSSRApolloClient({
        cache: new NextSSRInMemoryCache(),
        link:
            typeof window === 'undefined'
                ? ApolloLink.from([
                      new SSRMultipartLink({
                          stripDefer: true,
                      }),
                      queryLink,
                  ])
                : from([splitLink]),
    });
};

export function ApolloWrapper({ secure, gqlEndpoint, children }) {
    return <ApolloNextAppProvider makeClient={() => makeClient(secure, gqlEndpoint)}>{children}</ApolloNextAppProvider>;
}
