'use client';

import { Anchor, AppShell, Button, Center, ScrollArea, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconBolt, IconPlus } from '@tabler/icons-react';

import { AddPlantModalContent } from './ui/modals';

const Navbar = ({ toggleMobile, sparkUrl }) => {
    return (
        <AppShell.Navbar p={0}>
            <AppShell.Section grow my="md" mt={0} component={ScrollArea}>
                <Center mt="lg">
                    <Stack>
                        <Button
                            variant="gradient"
                            gradient={{ from: 'indigo', to: 'blue', deg: 90 }}
                            leftSection={<IconPlus size="1.2rem" />}
                            onClick={() => {
                                modals.open({
                                    title: (
                                        <Text fz="xl" pl="md" fw={500}>
                                            Aggiungi impianto in Datalogger
                                        </Text>
                                    ),
                                    children: <AddPlantModalContent />,
                                    size: 'xl',
                                });
                            }}
                        >
                            Aggiungi impianto
                        </Button>
                        <Button
                            component="a"
                            href={sparkUrl}
                            target="_blank"
                            variant="gradient"
                            gradient={{ from: 'indigo', to: 'violet', deg: 90 }}
                            leftSection={<IconBolt size="1.2rem" />}
                        >
                            Apri Spark
                        </Button>
                    </Stack>
                </Center>
            </AppShell.Section>
            <AppShell.Section p="md">
                <Text fz="xs" c="dimmed">
                    Powered by{' '}
                    <Anchor href="https://enami.it" target="_blank">
                        enami.it
                    </Anchor>
                </Text>
            </AppShell.Section>
        </AppShell.Navbar>
    );
};

export default Navbar;
